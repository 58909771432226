<template>
  <div class="col localization_rapper">
    <div>{{ ShowLocalization }}</div>
    <!-- <card>
      <el-form style="width: 100%" ref="ruleFormAdd" label-position="left">
        <el-form-item
          v-for="(item, i) in mainLocalization"
          :key="i"
          :label="`${i + 1} - ${item.key}`"
          class="form_items_wrapper text-capitalize"
        >
          <el-input
            type="text"
            v-model="mainLocalization[i].value"
            style="width: 100%"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="save">Save</el-button>
    </card> -->
  </div>
</template>

<script>
import { Card } from "src/components/index";
export default {
  data() {
    return {
      activeName: "first",
      id: null,
      localizationTranslationObject: {
        // Start Sidebar
        "User Dashboard (Sidebar)": "User Dashboard",
        "Admin Dashboard (Sidebar)": "Admin Dashboard",
        "My Account (Sidebar)": "My Account",
        "Edit Account (Sidebar)": "Edit Account",
        "Edit LCI (Sidebar)": "Edit LCI",
        "Personal Learning Profile (Sidebar)": "Personal Learning Profile",
        "Institutions (Sidebar)": "Institutions",
        "Management (Sidebar)": "Management",
        "Teams (Sidebar)": "Teams",
        "Logout (Sidebar)": "Logout",
        "Contacts (Sidebar)": "Contacts",
        "Groups (Sidebar)": "Groups",
        "Use Your Patterns (Sidebar)": "Use Your Patterns",
        "Users (Sidebar)": "Users",
        // End Sidebar

        // Start Admin Dashboard Page
        Activity: "Activity",
        Institutions: "Institutions",
        Tasks: "Tasks",
        Individuals: "Individuals",
        Students: "Students",
        "Institutional Users": "Institutional Users",
        // End Admin Dashboard Page

        // Start Profile
        "Share on twitter": "Share on twitter",
        "Share on facebook": "Share on facebook",
        "Edit score": "Edit score",
        "Print profile": "Print profile",
        "Share your profile": "Share your profile",
        "Personalize Your Profile": "Personalize Your Profile",
        "Add statement": "Add statement",
        "Delete statement": "Delete statement",
        "Read out loud": "Read out loud"
        // End Profile
      },
      LocalizationData: [
        // language: null,
        // localization: null,
      ],
      mainLocalization: [],
      testData: [
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        },
        {
          title: "contact key",
          inp: "contact value"
        }
      ]
    };
  },
  components: {
    Card
  },
  mounted() {
    this.refresh();
  },
  computed: {
    Localization() {
      return this.$store.getters["cms/getShowLocalization"];
    },
    testLocale() {}
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    refresh() {
      this.$store
        .dispatch("cms/getEveryLocalization", {
          id: this.$route.params.id
        })
        .then(() => {
          this.mainLocalization = this.Localization;
        });
    },

    save() {
      let objData = {};
      // let objData = this.localizationTranslationObject;
      this.mainLocalization.forEach(element => {
        objData[element.key] = element.value;
      });

      this.$store
        .dispatch("cms/EditLocalization", {
          id: this.$route.params.id,
          query: {
            localization: objData
          }
        })
        .then(() => {
          this.refresh();
          // this.$router.go(-1);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.form_items_wrapper {
}
::v-deep .card label {
  text-transform: capitalize;
  margin-bottom: 0;
}
::v-deep .el-form {
  // background: #eee;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-between;
  // align-self: auto;
  // background: #eee;
}
::v-deep .el-form-item {
  // background: #ccc;
  flex-grow: 0.3;
  // margin-bottom: 5px;
  max-width: 247.53px;
  // margin: auto;
}
::v-deep .el-form-item:last-of-type {
  margin-left: 0;
}
::v-deep .el-form-item__content {
  // background: #ccc;
  padding: 0;
}
</style>
